<template>
  <b-row>
    <b-col
      sm="12"
      md="3"
      xl="3"
    >
      <b-card>
        <treeselect
          v-model="selectedTag"
          :multiple="false"
          :options="tagSetOptionsList"
          :value-format="'object'"
          search-nested
          :default-expand-level="1"
          :disable-branch-nodes="true"
          placeholder="Select Tags"
        />

        <div
          v-if="selectedTag && selectedTag.selectType !== 'Radio'"
          class="mt-2"
        >
          <b-form-radio
            v-model="actionType"
            class="mr-2"
            name="actionType"
            value="add"
            inline
          >
            Add
          </b-form-radio>
          <b-form-radio
            v-model="actionType"
            name="actionType"
            value="remove"
            inline
          >
            Remove
          </b-form-radio>
        </div>

      </b-card>

      <b-card
        v-if="tagStatistics.length !== 0"
        no-body
        class="p-75"
      >
        <div
          v-for="tagSet in tagStatistics"
          :key="tagSet.id"
        >
          <b-card-body class="p-1">
            <h5>
              {{ tagSet.tag_set_name }}
            </h5>
            <ul class="list-unstyled ml-1">
              <li
                v-for="(tag, index) in tagSet.tags"
                :key="index"
              >
                <span class="align-middle">{{ tag.name }}: {{ tag.applications_count }}</span>
              </li>
            </ul>
          </b-card-body>
        </div>
      </b-card>
    </b-col>

    <b-col
      sm="12"
      md="9"
      xl="9"
    >
      <b-card
        no-body
      >
        <b-table
          :items="applicationsList"
          primary-key="id"
          show-empty
          :busy="isLoading"
          empty-text="No applications scanned"
          class="position-relative"
        >
          <!-- Loader -->
          <template #table-busy>
            <TableSpinner />
          </template>

          <!-- Column: role -->
          <template #cell(tags)="data">
            <badge-tag-list
              :tag-names="data.value"
            />
          </template>
        </b-table>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import { mapGetters } from 'vuex'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCard, BCardBody, BRow, BCol,
  BFormRadio, BTable,
} from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import BadgeTagList from '@/views/components/badge-tag-list/BadgeTagList.vue'

import store from '@/store'
import storeModule from '@/views/admin/tag-scanner/tagScannerStoreModule'

export default {
  name: 'TagScanner',
  components: {

    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormRadio,
    BTable,

    TableSpinner,
    BadgeTagList,

    Treeselect,
  },
  computed: {
    ...mapGetters({
      tagSetOptionsList: 'app-tag-scanner/getTagSetOptionsList',
      tagStatistics: 'app-tag-scanner/getTagStatistics',
      applicationsList: 'app-tag-scanner/getApplicationsList',
      isLoading: 'app-tag-scanner/getIsLoading',
      programId: 'verticalMenu/getDefaultProgram',
    }),
    selectedTags: {
      get() {
        return this.$store.getters['app-tag-scanner/getSelectedTags']
      },
      set(val) {
        this.$store.commit('app-tag-scanner/SET_SELECTED_TAGS', val)
      },
    },
    selectedTag: {
      get() {
        return this.$store.getters['app-tag-scanner/getSelectedTag']
      },
      set(val) {
        this.$store.commit('app-tag-scanner/SET_SELECTED_TAG', val)
      },
    },
    applicationId: {
      get() {
        return this.$store.getters['app-tag-scanner/getApplicationId']
      },
      set(val) {
        this.$store.commit('app-tag-scanner/INPUT_APPLICATION_ID', val)
      },
    },
    actionType: {
      get() {
        return this.$store.getters['app-tag-scanner/getActionType']
      },
      set(val) {
        this.$store.commit('app-tag-scanner/SET_ACTION_TYPE', val)
      },
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-tag-scanner'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
  watch: {
    selectedTag() {
      this.fetchTagStatistics()
    },
    async programId() {
      this.$store.commit('app-tag-scanner/RESET_STATE')
      await this.fetchTagSetsListOptions()
    },
  },
  async created() {
    await this.fetchTagSetsListOptions()
    window.addEventListener('keypress', this.handleScanner)
  },
  beforeDestroy() {
    this.$store.commit('app-tag-scanner/RESET_STATE')
  },
  destroyed() {
    window.removeEventListener('keypress', this.handleScanner)
  },
  methods: {
    handleScanner(e) {
      if (this.$router.currentRoute.name !== 'admin-tag-scanner') {
        return
      }

      if (!this.selectedTag) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select Tag first',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      if (e.key === 'Enter') {
        this.applyTags()
      } else {
        this.$store.commit('app-tag-scanner/SET_APPLICATION_ID', e.key)
      }
    },
    async fetchTagSetsListOptions() {
      await store.dispatch('app-tag-scanner/prepareTagSetsListOptions')
    },
    // async fetchTagStatistics() {
    //   if (this.selectedTags.length === 0) {
    //     store.commit('app-tag-scanner/SET_TAG_STATISTICS', [])
    //     return
    //   }
    //   const queryParams = {
    //     tag_id: this.selectedTags,
    //     with_empty_tags_on_statistics: true,
    //   }

    //   await store.dispatch('app-tag-scanner/fetchTagStatistics', { queryParams })
    // },
    async fetchTagStatistics() {
      if (this.selectedTags) {
        const queryParams = {
          tag_id: this.selectedTag.id,
          with_empty_tags_on_statistics: true,
        }

        await store.dispatch('app-tag-scanner/fetchTagStatistics', { queryParams })
      } else {
        store.commit('app-tag-scanner/SET_TAG_STATISTICS', [])
      }
    },
    async applyTags() {
      await this.$store.dispatch('app-tag-scanner/updateApplicationTag')
      this.fetchTagStatistics()
    },
    disableTagSelecting() {
      const tagOptions = [...this.tagSetOptionsList]
      tagOptions.forEach(tagSet => {
        const selectedIndex = tagSet.children.findIndex(tag => this.selectedTags.includes(tag.id))

        if (selectedIndex !== -1 && tagSet.selectType === 'Radio') {
          tagSet.children.forEach((tag, index) => {
            if (index !== selectedIndex) {
              // eslint-disable-next-line no-param-reassign
              tag.isDisabled = true
            }
          })
        } else {
          tagSet.children.forEach(tag => {
            // eslint-disable-next-line no-param-reassign
            tag.isDisabled = false
          })
        }
      })
      this.$store.commit('app-tag-scanner/SET_TAG_SET_OPTIONS_LIST', tagOptions)
    },
  },
}
</script>

<style lang="scss">
.vue-treeselect__label {
  font-size: 16px !important;
  height: 32px;
  }
</style>
